import * as helpers from '../helpers';

/**
 *  Class representing 'tarteaucitron' cookies manager
 */
export default class TarteaucitronCookies {
    /**
     * Create options for 'tarteaucitron' cookies manager
     * @param options - object with plugin settings https://opt-out.ferank.eu/en/install/
     */
    constructor(options) {
        this.language = options.language || 'fr';
        this.customText = options.customText || null;
        this.options = {
            hashtag: '#tarteaucitron', /* Open the panel with this hashtag */
            highPrivacy: true, /* Disable auto consent */
            orientation: 'bottom', /* Banner position (top - bottom) */
            adblocker: false, /* Show a Warning if an adblocker is detected */
            showAlertSmall: false, /* Show the small banner on bottom right */
            cookieslist: false, /* Show the cookie list */
            removeCredit: true, /* Remove credit link */
            useExternalCss: true, /* If false, the tarteaucitron.css file will be loaded */
            // cookieDomain: '.example.com' /* Shared cookie for subdomain website */
            DenyAllCta: false, /* Show the deny all button */
            mandatory: true, /* Show a message about mandatory cookies */
            showIcon: false, /* Show cookie icon to manage cookies */
            iconPosition: 'BottomRight', /* Position of the icon between BottomRight, BottomLeft, TopRight and TopLeft */
        };

        if (options instanceof Object) {
            this.options = helpers.extendDefaults(this.options, options.config);
        }

        tarteaucitronForceExpire = 180; // cookies lifetime in days

        this.init();
    }

    /**
     * initialise 'tarteaucitron' cookies manager
     * For Google Analytics and Matomo, 'useMatomoOrGoogleAnalytics', 'statisticsId' and 'matomoUrl'
     * variables come from www project, and are undefined in development context
     */
    init() {
        const cookiesToObject = (cookies) => {
            return cookies
                .split('!')
                .filter(cookie => cookie !== '')
                .reduce((current, next) => {
                    const [key, value] = next.split('=');
                    current[key] = value;
                    return current;
                }, {});
        };

        const handleFancyboxServiceCookies = () => {
            const cookies = cookiesToObject(tarteaucitron.cookie.read());

            if (cookies.youtube === 'false' || cookies.vimeo === 'false' || cookies.dailymotion === 'false') {
                tarteaucitron.cookie.create('fancybox', false);
            }
        };

        if (tarteaucitronForceLanguage !== undefined) {
            tarteaucitronForceLanguage = this.language;
        }

        if (tarteaucitronCustomText !== undefined && this.customText !== null) {
            tarteaucitronCustomText = this.customText;
        }

        // if (typeof useMatomoOrGoogleAnalytics !== 'undefined' && typeof statisticsId !== 'undefined') {
        //     if (useMatomoOrGoogleAnalytics === 'google') {
        //         tarteaucitron.user.gtagUa = statisticsId;
        //         tarteaucitron.user.analyticsAnonymizeIp = true;
        //     }
        //     if (useMatomoOrGoogleAnalytics === 'matomo' && typeof matomoUrl !== 'undefined') {
        //         tarteaucitron.user.matomoId = statisticsId;
        //         tarteaucitron.user.matomoHost = matomoUrl;
        //     }
        // }

        if (tarteaucitron) {
            tarteaucitron.services.fancybox = {
                key: 'fancybox',
                type: 'video',
                name: 'FancyboxService',
                needConsent: true,
                cookies: [],
                js() {},
                fallback() {
                    tarteaucitron.fallback(['js-fancybox-check-cookies'], (item) => {
                        const id = item.href.match(/youtube|vimeo|dailymotion/ig);

                        if (id) {
                            const [service] = id;
                            const cookies = cookiesToObject(tarteaucitron.cookie.read());

                            if (cookies[service] === 'true') {
                                return item;
                            }

                            const itemToPaste = document.createElement('button');
                            const tempNode = document.createElement('div');

                            tempNode.innerHTML = tarteaucitron.engage(service);

                            const [text, button] = tempNode.querySelector('.tac_float').childNodes;

                            itemToPaste.className = `${button.className} ${item.className} cookies-denied`;
                            itemToPaste.id = button.id;
                            itemToPaste.innerHTML = '';
                            itemToPaste.appendChild(text);
                            itemToPaste.innerHTML = `<span>${itemToPaste.innerHTML.trim()}</span>`;
                            itemToPaste.setAttribute('type', 'button');

                            item.parentElement.replaceChild(itemToPaste, item);

                            itemToPaste.addEventListener('click', (e) => {
                                e.preventDefault();
                                location.reload();
                            });

                            const galleryList = itemToPaste.classList.contains('gallery-item')
                                ? itemToPaste.parentElement.parentElement
                                : itemToPaste.parentElement;

                            if (galleryList) {
                                const haveLinks = galleryList.querySelector('a');
                                const hasBlockedElements = galleryList.querySelector('button');

                                if (!haveLinks) {
                                    galleryList.classList.add('no-links');
                                }

                                if (hasBlockedElements) {
                                    galleryList.classList.add('has-blocked-elements');
                                }
                            }

                            return itemToPaste;
                        }
                    }, true);
                },
            };

            tarteaucitron.reloadThePage = true;
            tarteaucitron.events.load = handleFancyboxServiceCookies;

            window.addEventListener('tac.root_available', () => {
                const CTAButtons = [...document.querySelectorAll('.tarteaucitronCTAButton')];
                CTAButtons.forEach((button) => {
                    button.addEventListener('click', () => {
                        location.reload();
                    });
                });
            });

            window.addEventListener('tac.close_panel', handleFancyboxServiceCookies);

            tarteaucitron.init(this.options);
        }
    }

    /**
     * Add services to cookies list
     * @param services - {String} services list
     */
    addServices(services) {
        const servicesArray = services.split(',');

        servicesArray.forEach((service) => {
            (tarteaucitron.job = tarteaucitron.job || []).push(service.trim());
        });

        if (tarteaucitron.job.length) {
            const serviceAllowButtonsHandler = () => {
                const tacRoot = document.getElementById('tarteaucitronRoot');
                const allowButtons = [...document.querySelectorAll('.tarteaucitronAllow:not(.has-listener)')]
                    .filter(item => item && !tacRoot.contains(item));

                allowButtons.forEach((button) => {
                    button.classList.add('has-listener');

                    button.addEventListener('click', () => {
                        location.reload();
                    });
                });
            };

            tarteaucitron.job.forEach((serviceName) => {
                document.addEventListener(`${serviceName}_added`, serviceAllowButtonsHandler);
            });
        }
    }
}
