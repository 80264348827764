import TarteaucitronCookies from './components/tarteaucitron-stratis';
import l10n from './lang/locallang';

document.addEventListener('DOMContentLoaded', () => {
    // Comply to the european cookie law
    const cookies = new TarteaucitronCookies({
        language: 'fr',
        customText: {
            alertBigClick: '',
            alertBig: l10n.cookieMessage,
            alertBigPrivacy: 'Afin de vous proposer des vidéos, des boutons de partage, des contenus remontés des réseaux sociaux et d\'élaborer des statistiques de fréquentation, nous sommes susceptibles de déposer des cookies tiers sur votre machine. Cela ne peut se faire qu\'en obtenant, au préalable, votre consentement pour chacun de ces cookies.',
            disclaimer: 'Ce site propose de personnaliser vos contenus et votre navigation. Lorsque vous naviguez sur ce site Internet, des informations sont susceptibles d\'être enregistrées (cookies) sur votre terminal, sous réserve de vos choix.<br>' +
                '<br>' +
                'La durée de validité de votre consentement ou de votre refus est de 6 mois.<br>' +
                'Pour en savoir plus, consultez notre <a href="#">politique de protection des données.</a>',
        },
        config: {
            cookieName: 'tartaucitron',
            hashtag: '#tarteaucitron', /* Ouverture automatique du panel avec le hashtag */
            highPrivacy: true, /* désactiver le consentement implicite (en naviguant) ? */
            bodyPosition: 'top', /* position de la bannière en html */
            orientation: 'bottom', /* le bandeau doit être en haut (top) ou en bas (bottom) ? */
            adblocker: false, /* Afficher un message si un adblocker est détecté */
            showAlertSmall: false, /* afficher le petit bandeau en bas à droite ? */
            cookieslist: false, /* Afficher la liste des cookies installés ? */
            removeCredit: true, /* supprimer le lien vers la source ? */
            handleBrowserDNTRequest: false,
            AcceptAllCta: true,
            moreInfoLink: true,
            DenyAllCta: true, /* Show the deny all button */
            // cookieDomain: '.example.com' /* Nom de domaine sur lequel sera posé le cookie pour les sous-domaines */
        },
    });

    // Add services to cookies manager
    cookies.addServices('youtube, vimeo, fancybox');
});
